import { createSlice } from "@reduxjs/toolkit";
import GeoIpApi from "api/geoip.api";
import countries from "assets/json/country.json";
import website from "assets/json/website.json";

const initialState = {
  isLoading: false,
  error: null,
  country: "Singapore",
  delivery: "Singapore",
  language: localStorage.getItem("language") || "English",
  currency: localStorage.getItem("currency") || "SGD",
  countries: countries,
  website: website,
  deliveries: [],
  languages: [],
  currencies: [],
};

const slice = createSlice({
  name: "country",
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    hasError: (state, action) => {
      state.error = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
      state.delivery = action.payload;
      // localStorage.setItem("country", action.payload);
      // localStorage.setItem("delivery", action.payload);
    },
    setDelivery: (state, action) => {
      state.delivery = action.payload;
      // localStorage.setItem("delivery", action.payload);
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
      localStorage.setItem("language", action.payload);
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setCountries: (state, action) => {
      state.countries = action.payload;
    },
    setDeliveries: (state, action) => {
      state.deliveries = action.payload;
    },
    setLanguages: (state, action) => {
      state.languages = action.payload;
    },
    setCurrencies: (state, action) => {
      state.currencies = action.payload;
    },
  },
});

export const {
  setIsLoading,
  hasError,
  setCountry,
  setDelivery,
  setLanguage,
  setCurrency,
  setCountries,
  setDeliveries,
  setLanguages,
  setCurrencies,
} = slice.actions;

export default slice.reducer;

const selectCountryByCode = (code) => {
  return countries.find((country) => country.code === code);
};

export const fetchGeoIp = () => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const response = await GeoIpApi.getGeoIp();
    const { geo } = response;
    const country = selectCountryByCode(geo.country);
    dispatch(setCountry(country.label));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};
