/* eslint-disable react-hooks/rules-of-hooks */
import LoadingLazy from "components/LoadingLazy";
import Checkout from "features/Checkout";
import Layout from "Layout";
import AuthLayout from "Layout/Auth";
import DistilleryLayout from "Layout/DistilleryLayout";
import SingaporeLayout from "Layout/Singapore";
import VietNamLayout from "Layout/VietNam";
import React, { Suspense, useEffect, useState } from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import {
  PATHS,
  PATHS_AUTH,
  PATHS_DISTILLERY,
  PATHS_MY_ACCOUNT,
  PATHS_VN,
} from "./paths";
import Membership from "features/Membership";
import Storage from "features/Storage";

export const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();
  const [, setIsHome] = useState(false);
  useEffect(() => {
    if (!pathname.split("/")[2] || pathname.split("/")[2] === "home") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [pathname]);

  return (
    <Suspense fallback={<LoadingLazy isSite={pathname.includes(PATHS.root)} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          path: PATHS.root,
          element: <SingaporeLayout />,
          children: [
            { path: PATHS.root, element: <Home /> },
            { path: PATHS.home, element: <Home /> },
            { path: PATHS.about, element: <About /> },
            { path: PATHS.howToBid, element: <HowToBid /> },
            { path: PATHS.howToSell, element: <HowToSell /> },
            { path: PATHS.shipping, element: <Shipping /> },
            { path: PATHS.fAQ, element: <FAQ /> },
            { path: PATHS.termsAndConditions, element: <TermsAndCondition /> },
            { path: PATHS.glossary, element: <Glossary /> },
            { path: PATHS.independenBottlers, element: <IndependenBottlers /> },
            { path: PATHS.auctionSchedule, element: <AuctionSchedule /> },
            { path: PATHS.buy_whisky_cask, element: <BuyWhiskyCask /> },
            {
              path: PATHS.singleIndependentBottlers,
              element: <SingleIndependent />,
            },
            { path: PATHS.partnerShips, element: <PartnerShips /> },
            { path: PATHS.membership, element: <Membership /> },
            { path: PATHS.storage, element: <Storage /> },
            { path: PATHS.deliveryCalculator, element: <DeliveryCalculator /> },
            {
              // New and Articles
              path: PATHS.newsAndArticles.root,
              children: [
                {
                  path: PATHS.newsAndArticles.root,
                  element: <NewsAndArticles />,
                },
                {
                  path: PATHS.newsAndArticles.detail,
                  element: <NewsAndArticlesDetail />,
                },
                {
                  path: PATHS.newsAndArticles.submit,
                  element: <SubmitArticles />,
                },
                {
                  path: PATHS.newsAndArticles.submitUpdate,
                  element: <SubmitArticlesUpdate />,
                },
              ],
            },
            {
              path: PATHS.auctionPreview,
              element: <AuctionPreview />,
            },
            {
              path: PATHS.whiskyAuction.root,
              children: [
                {
                  path: PATHS.whiskyAuction.detail,
                  element: <Auction />,
                },
                {
                  path: PATHS.whiskyAuction.product,
                  element: <SingleAuction />,
                },
              ],
            },
            {
              path: PATHS.previousAuctions.root,
              // element: <PreviousBid />,
              children: [
                {
                  path: PATHS.previousAuctions.root,
                  element: <PreviousBid />,
                },
                {
                  path: PATHS.previousAuctions.detail,
                  element: <PreviousBid />,
                },
                {
                  path: PATHS.previousAuctions.detailProduct,
                  element: <AuctionPrevious />,
                },
                {
                  path: PATHS.previousAuctions.product,
                  element: <SinglePrevious />,
                },
                // {
                //   path: PATHS.previousAuctions.malaysiaDetail,
                //   element: <AuctionPrevious />,
                // },
                // {
                //   path: PATHS.previousAuctions.vietnamDetail,
                //   element: <AuctionPrevious />,
                // },
              ],
            },
            {
              path: PATHS.reviewAndRating.root,
              children: [
                {
                  path: PATHS.reviewAndRating.root,
                  element: <ReviewAndRating />,
                },
                {
                  path: PATHS.reviewAndRating.form,
                  element: <RatingForm />,
                },
              ],
            },
            {
              path: PATHS.registerLots.root,
              // element: <RegisterLots />,
              children: [
                {
                  path: PATHS.registerLots.root,
                  element: <SubmitLots />,
                },
                {
                  path: PATHS.registerLots.submitLots,
                  element: <SubmitLots />,
                },
                {
                  path: PATHS.registerLots.requestValuation,
                  element: <RequestValuation />,
                },
              ],
            },
            {
              path: PATHS.dram.root,
              children: [
                {
                  path: PATHS.dram.root,
                  element: <DramShop />,
                },
                {
                  path: PATHS.dram.dramDistillery,
                  element: <DramShop />,
                },
                {
                  path: PATHS.dram.product,
                  element: <SingleShop />,
                },
              ],
            },
            {
              path: PATHS.bottleshop.root,
              children: [
                {
                  path: PATHS.bottleshop.root,
                  element: <BottleShop />,
                },
                {
                  path: PATHS.bottleshop.detail,
                  element: <BottleShop />,
                },
                {
                  path: PATHS.bottleshop.detailCategory,
                  element: <BottleShop />,
                },
                {
                  path: PATHS.bottleshop.product,
                  element: <SingleShop />,
                },
              ],
            },
            {
              path: PATHS.caskShop.root,
              children: [
                {
                  path: PATHS.caskShop.root,
                  element: <CaskShop />,
                },
                {
                  path: PATHS.caskShop.product,
                  element: <SingleShop />,
                },
              ],
            },
            {
              path: PATHS.bottleshopDistillery,
              element: <BottleShop />,
            },
            {
              path: PATHS.product.root,
              children: [
                {
                  path: PATHS.product.detail,
                  element: <Single />,
                },
                {
                  path: PATHS.product.detailAuction,
                  element: <Single />,
                },
                {
                  path: PATHS.product.previousAuction,
                  element: <SinglePrevious />,
                },
              ],
            },
            {
              path: PATHS.cart.root,
              children: [
                {
                  path: PATHS.cart.root,
                  element: <Cart />,
                },
                {
                  path: PATHS.cart.secret,
                  element: <Cart />,
                },
              ],
            },
            {
              path: PATHS.checkout.root,
              children: [
                {
                  path: PATHS.checkout.root,
                  element: <Checkout />,
                },
              ],
            },
            {
              path: PATHS.unsubscribe,
              element: <Unsubscribe />,
            },
          ],
        },
        {
          path: PATHS_VN.root,
          element: <VietNamLayout />,
          children: [{}],
        },
      ],
    },
    {
      path: PATHS_DISTILLERY.root,
      element: <DistilleryLayout />,
      children: [
        {
          path: PATHS_DISTILLERY.root,
          element: <Distillery />,
        },
        {
          path: PATHS_DISTILLERY.detail,
          element: <Distillery />,
        },
      ],
    },

    {
      path: PATHS_AUTH.root,
      element: <AuthLayout />,
      children: [
        {
          path: PATHS_AUTH.root,
          element: <LoginAndRegister />,
        },
        {
          path: PATHS_AUTH.LOGIN,
          element: <Login />,
        },
        {
          path: PATHS_AUTH.REGISTER,
          element: <Register />,
        },
        {
          path: PATHS_AUTH.FORGOT_PASSWORD,
          element: <ForgotPassword />,
        },
        {
          path: PATHS_AUTH.RESET_PASSWORD,
          element: <ResetPassword />,
        },
        {
          path: PATHS_AUTH.verifyEmail,
          element: <VerifyEmail />,
        },
      ],
    },
    {
      path: PATHS_MY_ACCOUNT.root,
      element: <SingaporeLayout />,
      children: [
        {
          path: PATHS_MY_ACCOUNT.root,
          element: <MyAccount />,
        },

        // {
        //   path: PATHS_MY_ACCOUNT.cropprofile,
        //   element: <CropProfile />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.changePassword,
        //   element: <ChangePassword />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.wallet,
        //   element: <Wallet />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.reward,
        //   element: <Reward />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.sellerWallet,
        //   element: <Seller />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.mybids,
        //   element: <MyBids />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.mysales,
        //   element: <MySales />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.mywishlist,
        //   element: <MyWishList />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.orderHistory,
        //   element: <OrderHistory />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.reviewHistory,
        //   element: <RewiewHistory />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.articleHistory,
        //   element: <ArticleHistory />,
        // },
        // {
        //   path: PATHS_MY_ACCOUNT.settings,
        //   element: <Settings />,
        // },
        {
          path: "*",
          element: <MyAccount />,
        },
      ],
    },
    // if path = /, redirect to /home
    {
      path: "/",
      element: <Navigate to={PATHS.root} />,
    },
    {
      path: "*",
      element: <ForZFor />,
    },
  ]);
}
const ForZFor = Loadable(
  React.lazy(() => import("features/MyAccount/components/404"))
);
const Home = Loadable(React.lazy(() => import("features/Home")));
const About = Loadable(React.lazy(() => import("features/About")));
const HowToBid = Loadable(React.lazy(() => import("features/HowToBid")));
const HowToSell = Loadable(React.lazy(() => import("features/HowToSell")));
const Shipping = Loadable(React.lazy(() => import("features/Shipping")));
const FAQ = Loadable(React.lazy(() => import("features/FAQ")));
const TermsAndCondition = Loadable(
  React.lazy(() => import("features/TermsAndConditions"))
);
const Glossary = Loadable(React.lazy(() => import("features/Glossary")));
const IndependenBottlers = Loadable(
  React.lazy(() => import("features/IndependenBottlers"))
);
const PartnerShips = Loadable(
  React.lazy(() => import("features/PartnerShips"))
);
const NewsAndArticles = Loadable(
  React.lazy(() => import("features/NewsAndArticles"))
);
const NewsAndArticlesDetail = Loadable(
  React.lazy(() => import("features/NewsAndArticles/Detail"))
);
const SubmitArticles = Loadable(
  React.lazy(() => import("features/NewsAndArticles/SubmitArticles"))
);
const SubmitArticlesUpdate = Loadable(
  React.lazy(() => import("features/NewsAndArticles/SubmitArticlesUpdate"))
);
const Auction = Loadable(React.lazy(() => import("features/Auction")));
const AuctionPreview = Loadable(
  React.lazy(() => import("features/AuctionPreview"))
);
const AuctionPrevious = Loadable(
  React.lazy(() => import("features/AuctionPrevious"))
);
const AuctionSchedule = Loadable(
  React.lazy(() => import("features/AuctionSchedule"))
);

const PreviousBid = Loadable(React.lazy(() => import("features/PreviousBid")));
const ReviewAndRating = Loadable(
  React.lazy(() => import("features/ReviewAndRating"))
);
const RatingForm = Loadable(
  React.lazy(() => import("features/ReviewAndRating/RatingForm"))
);
const SubmitLots = Loadable(React.lazy(() => import("features/SubmitLots")));
const RequestValuation = Loadable(
  React.lazy(() => import("features/RequestValuation"))
);

const DramShop = Loadable(
  React.lazy(() => import("features/Shopping/DramShop"))
);
const BottleShop = Loadable(
  React.lazy(() => import("features/Shopping/BottleShop"))
);
const Single = Loadable(React.lazy(() => import("components/Product/Single")));
const SinglePrevious = Loadable(
  React.lazy(() => import("components/Product/SinglePrevious"))
);
// Auth
const LoginAndRegister = Loadable(
  React.lazy(() => import("components/Auth/LoginAndRegister"))
);
const Login = Loadable(React.lazy(() => import("components/Auth/Login")));
const Register = Loadable(React.lazy(() => import("components/Auth/Register")));
const ForgotPassword = Loadable(
  React.lazy(() => import("components/Auth/ForgotPassword"))
);
const ResetPassword = Loadable(
  React.lazy(() => import("components/Auth/ResetPassword"))
);
const VerifyEmail = Loadable(
  React.lazy(() => import("components/Auth/VerifyEmail"))
);

// Cart
const Cart = Loadable(React.lazy(() => import("features/Cart")));
// const Checkout = Loadable(React.lazy(() => import("components/Cart/Checkout")));
// const CheckoutSuccess = Loadable(
//   React.lazy(() => import("components/Cart/CheckoutSuccess"))
// );

// My Account
const MyAccount = Loadable(React.lazy(() => import("features/MyAccount")));
const Distillery = Loadable(React.lazy(() => import("features/Distillery")));

const SingleIndependent = Loadable(
  React.lazy(() => import("features/IndependenBottlers/SingleIndependent"))
);

const DeliveryCalculator = Loadable(
  React.lazy(() => import("features/DeliveryCalculator"))
);

const CaskShop = Loadable(
  React.lazy(() => import("features/Shopping/CaskShop"))
);

const BuyWhiskyCask = Loadable(
  React.lazy(() => import("features/BuyWhiskyCask"))
);

const SingleAuction = Loadable(
  React.lazy(() => import("components/Product/Single/SingleAuction"))
);

const SingleShop = Loadable(
  React.lazy(() => import("components/Product/Single/SingleShop"))
);

const Unsubscribe = Loadable(React.lazy(() => import("features/Unsubscribe")));
